@import '~libs/react-mpk/styles/var';

.activity-logs{
  .log-item{
    padding: $mpk-padding-S 0;
    position: relative;
    &:before{
      content:'';
      position: absolute;
      left: 14px;
      top: 16px; 
      width: 1px;
      background: #888;
      height: 100%;
      z-index:0;
    }
    &:last-child:before{
      display: none;
    }
    .node{
      margin-right: $mpk-padding-N;
      width: 28px;
      height: 28px;
      border-radius: 28px;
      display: flex;
      align-content: center;
      justify-content: center;
      background: $mpk-color-success;
      border: 2px solid #444;
      z-index: 2;
      .md-icon{
        font-size: 16px;
        color: white;
      }
    }
    .label{
      
    }
    .info{
      font-size: $mpk-font-size-NS;
      color: $mpk-font-color-D3;
    }
    .date{
      font-size: $mpk-font-size-S;
      margin-right: $mpk-padding-N;
      color: $mpk-font-color-D3;
    }
    .error{
      margin-top: $mpk-padding-S;
      color: $mpk-color-warn;
    }
    &.is-error{
      .node{
        background: $mpk-color-warn;
      }
    }
  }
}