#submission-form{
  .pdf-input{
    min-width: 220px !important;
    &.active{
      * {
        color: #1565c0 !important;
        font-weight: bold;
      }
    }
    .alert{
      text-transform: none;
    }
    .md-icon-text{
      flex: 1;
    }
    .md-btn--text{
      height: auto !important;
    }
  }
}