@import '~libs/react-mpk/styles/var';

.submission-progress-badges{
  > .md-btn {
    border-radius: 24px;
    width: auto;
    padding: 0 4px 0 12px !important;
    position: relative;
    background: #333;
    text-align: left;
    .progress{
      position: absolute;
      top: 0;
      right: -29px;
      width: 100%;
      height: 100%;
    }
    .total{
      background: #444;
      border-radius: 24px;
      padding: 4px 8px;
      color: #aaa;
      &.run{
        background:#fafafa;
        color: black;
      }
    }
    .info{
      font-size: 10px;
      color: white !important;
      text-transform: none;
      margin-right: 8px;
      text-align: right !important;
      > div:first-child{
        font-size: 8.4px;
      }
    }
  }
}

@media screen and (max-width: $mpk-media-xs){
  .submission-progress-badges{
    > .md-btn {
      padding: 0 4px !important;
      .info{
        display: none;
      }
    }
  }
}